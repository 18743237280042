.apply_form {
    width: 100%;
    padding: 0 3rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-control {
    width: 100%;
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form_control_work_type_wrapper,
.form_control_subcategory_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.form_control_subcategory {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    position: relative;
}

.form-control label {
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-color);
}

@media screen and (max-width: 660px) {
    .apply_form {
        padding: 0;
    }
}

.job_form_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    margin-bottom: 10px;
}

.form_control_subcategory .form_label_subcategory,
.form_control_subcategory .form_label_subcategory_imported {
    max-width: max-content;
}

.form_control_subcategory .form_label_workType,
.form_control_subcategory .form_label_workType_imported {
    max-width: max-content;
}

@media screen and (max-width: 500px) {
    
    .form_label_workType_imported,
    .form_label_subcategory_imported {
        max-width: 80px;
    }
}

/* CLOSE BUTTON START */

.close_bar_btn_wrapper {
    position: absolute;
    display: flex;
    width: 30px;
    height: 20px;
    top: 2rem;
    flex-direction: column;
    right: 1rem;
    justify-content: space-between;
}

.close_bar_btn {
    position: relative;
}
/* CLOSE BUTTON END */
