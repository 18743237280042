.data_selector_root {
    display: inline-flex;
    height: 30px;
    cursor: pointer;
    align-items: center;
    min-width: 170px;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #E5E5E5;
    background-color: #FFFFFF;
    color: #000000;
    text-transform: capitalize;
}

.data_selector_root.data_selector_opened,
.selected_item,
.data_selector_list_item:hover {
    background-color: #F1F1F1;
}

.data_selector_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
}

.data_selector_root .data_selector_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 29px;
    left: 0;
    width: max-content;
    min-width: 170px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
    border: 1px solid #E5E5E5;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    padding: 5px 0;
    border-radius: 10px;
    z-index: 2;
}

.data_selector_list_item {
    padding-left: 15px;
    padding-right: 10px;
    min-height: 30px;
    width: 100%;
}