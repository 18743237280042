.poster_list_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--header-bg-color);
    margin-bottom: 20px;
    height: 160px;
}

/* COMPANY LOGO START */

.left_company_logo {
    width: 120px;
    height: 100%;
}

.left_company_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* COMPANY LOGO END */

/* COMPANY INFO START */

.right_company_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--ligher-blue-color);
    width: 100%;
    height: 100%;
}

.company_info_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px 0 0 15px;
}

@media screen and (max-width: 500px) {
    .company_info_header {
        padding: 15px 0 0 5px;
    }
    
    .company_info_header h1 {
        font-size: 14px;
    }
}    

.company_info_name {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

.company_info_date {
    margin-right: 10px;
    font-size: var(--main-font-size);
    color: var(--gray-color);
}

.company_info_date:before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    background-image: url(https://dev.bg/wp-content/themes/jobsdevbg/images/office-monthly-calendar.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    vertical-align: sub;
}

.company_info_additional_info {
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: flex-end;
}

.company_info_city_wrapper,
.company_info_salary_wrapper {
    display: inline-block;
    color: var(--header-bg-color);
    font-size: var(--main-font-size);
    font-weight: 400;
    padding: 6px 16px 5px 16px;
    margin-right: 16px;
    margin-bottom: 5px;
    border-radius: 25px;
    min-width: 70px;
    text-align: center;
}

.company_info_city_wrapper {
    background-color: var(--darker-green-color);
}

.company_info_salary_wrapper {
    background-color: #8fbc8f;
}

.company_actions {
    order: -1;
    margin: 5px 10px;
}

/* RESPONSIVE */

@media screen and (max-width: 450px) {
    .company_info_right_arrow {
        display: none;
    }
    
    .company_actions,
    .company_info_city_wrapper,
    .company_info_salary_wrapper {
        margin: 0;
    }
}

/* COMPANY INFO END */
