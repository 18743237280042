@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap');


$font-stack: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$primary-color: #000000;
$primary-font-size: 14px;



body {
    margin: 0;
    font-family: $font-stack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 360px;
    font-size: $primary-font-size;
    color: $primary-color;
}

.container {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
}

/* VALIDATION */
.invalid {
    font-weight: 700;
    color: red;
}

.invalid input,
.invalid textarea {
    border: 2px solid red;
}

a {
    text-decoration: none;
}