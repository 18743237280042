ul.user_links {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0;
    padding: 0;
}

.user_links li:nth-child(1) a,
.user_links li:nth-child(2) a {
    color: var(--gray-color);
}

ul.user_links li a {
    display: block;
    padding: 1rem;
}

.logout_btn {
    text-transform: uppercase;
}

@media (max-width: 590px) {
    ul.user_links {
        flex-direction: column;
        width: 100%;
    }

    ul.user_links li {
        text-align: center;
    }
}