.category_form {
    width: 100%;
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-control {
    width: 100%;
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-control label {
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-color);
}

.form-actions {
    display: flex;
    margin-top: 1rem;
    flex-shrink: 1;
    align-self: flex-start;
}
