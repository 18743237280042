@use 'sass:math';

$loader-color: #0052ec;
$loader-size: 56px;
$loader-height: 20px;
$loader-border-size: 8px;
$loader-gap: 12px;
$loader-animation-duration: 1s;

@mixin loader09(
    $size: $loader-size, 
    $height: $loader-height, 
    $color: $loader-color, 
    $duration: $loader-animation-duration, 
    $gap: $loader-gap, 
    $align: null
) {
    $unique-name: unique-id();
    width: $size;
    height: $height;
    background: $color;
    animation: #{'loader09-'}#{$unique-name} $duration ease-in-out infinite;
    animation-delay: math.div($duration, 5) * 2;
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: $size;
        height: $height;
        background: $color;
        animation: #{'loader09-'}#{$unique-name} $duration ease-in-out infinite;
    }

    &::before {
        right: $size + $gap;
        animation-delay: math.div($duration, 5) * 1;
    }

    &::after {
        left: $size + $gap;
        animation-delay: math.div($duration, 5) * 3;
    }

    @if ($align == center) {
        margin: 0 auto;
    }

    @if ($align == middle) {
        top: 50%;
        margin: -($size * 2 + $gap) auto 0;
    }

    @keyframes #{'loader09-'}#{$unique-name} {
        0%, 100% {
            box-shadow: 0 0 0 $color,
            0 0 0 $color;
        }

        50% {
            box-shadow: 0 (-$gap) 0 $color,
            0 $gap 0 $color;
        }
    }
}

.base_spinner_alt_root {
    @include loader09;
}