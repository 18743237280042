.edit_form_wrapper {
    width: 80%;
    position: fixed;
    z-index: 100;
    background-color: var(--main-bg-color);
    left: 10%;
    top: 5%;
    bottom: 5%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    max-height: 100%;
    overflow-x: hidden;
    height: auto;
    padding: 0.5rem;
    border-radius: 12px;
}

.edit_form_wrapper .edit_form {
    overflow-y: auto;
    margin: 0;
    padding: 0;
    width: unset;
}

@media screen and (max-width: 500px) {
    .edit_form_wrapper {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        max-height: unset;
        
    }
    
    .edit_form_wrapper .edit_form {
        padding: 0;
        margin: 0 0 0 5px;
    }
}