.board_skeleton_loading_root {
    width: 90%;
    max-width: 1100px;
    height: 100vh;
    z-index: 3;
    border-radius: 15px;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}