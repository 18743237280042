@use 'styles/base';

:root {
  --main-font-size: base.$primary-font-size;
  --title-font-size: 15px;
  /* COLORS */
  --header-bg-color: #FFFFFF;
  --main-bg-color: #F2F2F2;
  --footer-bg-color: #EBEBEB;
  --slider-bg-color: #E2E2E2;
  /* BLUE */
  --blue-text-color: #213294;
  --ligher-blue-color: #DDEEFF;
  --nav-btn-bg-color: #EBF3FF;
  --nav-hover-btn-bg-color: #DAE9FF;
  --blue-bg-color: #304FFE;
  --purple-bg-color: #233CC8;
  /* GREEN */
  --lighter-green-color: #DBE4C7;
  --light-green-color: #8fbc8f;
  --green-text-color: #B3CE66;
  --darker-green-color: #556b2f;
  /* GRAY */
  --gray-color: #595959;
  --darker-gray-color: #F4F4F4;
  --lighter-gray-color: rgba(242, 242, 242, 0.5);
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}