.footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 
            'header header'
            'column1 column2'
            'column1 column2';
    width: 100%;
    padding: 0 30px 0 30px;
    column-gap: 30px;
}

.footer_header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
}

.footer_header .footer_header_circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: var(--lighter-green-color);
    border-radius: 100%;
}

.footer_header h1 {
    color: var(--blue-text-color);
    font-size: 24px;
    font-weight: 700;
}

.footer_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--blue-text-color);
}

.bar {
    display: block;
    width: 40px;
    border: 3px solid var(--blue-bg-color);
    background-color: var(--blue-bg-color);
}

.footer_column_jobs {
    grid-area: column1;
}

.footer_column_follow_us {
    grid-area: column2;
}

/* JOBS SECTION */
.footer_column_jobs {
    display: flex;
    flex-direction: column;
}

.footer_column_jobs a {
    font-size: 18px;
    color: var(--blue-text-color)
}

/* FOLLOW US SECTION */
.footer_column_media_icons {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

/* RESPONSIVE START */

@media (max-width: 550px) {
    .footer {
        column-gap: 40px;    
    }
    
    .footer_column .footer_column_content h1 {
        font-size: var(--title-font-size);
    }
}

/* RESPONSIVE END */