/* EXTEND / INHERITANCE */
%flexbox_shared {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.job_details_applying_wrapper {
    @extend %flexbox_shared;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 20px;
}

.job_details_wrapper.job_details_wrapper_apply_form_is_visible {
    width: 65%;
}

.job_details_wrapper,
.applying_wrapper {
    @extend %flexbox_shared;
    justify-content: space-between;
    align-items: stretch;
}

/* JOB ITEM START */
.job_item_wrapper {
    width: 100%;
    margin-bottom: 30px;
}

.job_item_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color:  var(--ligher-blue-color);
    height: unset;
    min-height: 150px;
}

.job_item_header_applying_btn {
    @extend %flexbox_shared;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.job_item_header_title {
    display: flex;
    flex-direction: column;
    color: var(--gray-color);
    font-size: 24px;
}

.job_item_header_counter {
    font-size: 12px;
    color: var(--gray-color);
}

.job_item_work_type_badges {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    margin-top: 10px;
}

.remote_badge {
    text-transform: capitalize;
}

/* JOB ITEM END */

/* JOB INFO START */

.job_info_wrapper {
    width: 65%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-right: 30px;
}

.job_info_wrapper.job_info_wrapper_apply_form_is_visible,
.sidebar_wrapper.sidebar_wrapper_apply_form_is_visible {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
}

.job_info_wrapper .job_info_badge_wrapper {
    display: flex;
    flex-wrap: wrap;
    height: unset;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

/* JOB INFO END */


/* SIDEBAR START */

.sidebar_wrapper {
    width: 30%;
}

.sidebar_container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex-wrap: wrap;
}

.sidebar_container .sidebar_company_logo img {
    object-fit: contain;
}

.sidebar_company_title {
    font-weight: 700;
    font-size: 20px;
}

.sidebar_company_desc {
    padding: 0.5rem;
}

/* SIDEBAR END */

/* APPLYING WRAPPER START */

.applying_wrapper {
    width: 35%;
    justify-content: center;
    
}

/* APPLYING WRAPPER END */


/* RESPONSIVE */

@media screen and (max-width: 900px) {
    
    .job_info_wrapper,
    .sidebar_wrapper {
        width: 100%;
    }
    
    .sidebar_container {
        align-items: center;
    }

    .sidebar_container .sidebar_company_logo {
        width: 250px;
        height: 350px;
    }

    .sidebar_container .sidebar_company_logo img {
        width: 100%;
        height: 100%;
    }
    
    .job_info_wrapper {
        margin-right: 0;
        margin-bottom: 30px;
    }
    
    .job_details_applying_wrapper {
        flex-direction: column;
    }
    
    .applying_wrapper {
        order: -1;
        margin-bottom: 30px;
    }
    
    .job_details_wrapper.job_details_wrapper_apply_form_is_visible,
    .applying_wrapper {
        width: 90%;
    }
    
}

@media screen and (max-width: 470px) {
    
    .job_item_header_title {
        font-size: 18px;
    }
}