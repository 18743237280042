.pop_up_root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 19vh;
    left: 3%;
    transform: translateX(-3%);
    width: 300px;
    height: 50px;
    z-index: 100;
    border: none;
    border-radius: 12px;
    background-color: #B3CE66;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.pop_up_root span {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
}