/* EACH SUBCATEGORY BADGE START */

div.homepage_category_subcategory_wrapper {
    display: flex;
    min-width: 120px;
    height: 40px;
    border-radius: 15px;
    justify-content: space-around;
    align-items: center;
    background-color: var(--darker-gray-color);
}

span.homepage_category_subcategory_title {
    font-size: 13px;
    font-weight: 400;
}

div.homepage_category_subcategory_counter_wrapper {
    
}

h1.homepage_category_subcategory_counter {
    font-size: 12px;
    font-weight: 400;
}

/* EACH SUBCATEGORY BADGE END */
