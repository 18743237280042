.page_content_wrapper {
    width: 70%;
}

.page_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page_content_header {
    font-size: 22px;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: 660px) {
    .page_content_wrapper {
        width: 90%;
    }
}