.navbar {
    display: flex;
    position: relative;/*to position the hamburger relative to the nav bar*/
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--header-bg-color);
    box-shadow: inset 0 4px 9px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    height: 90px;
}

.navbar_title {
    color: var(--blue-bg-color);
    font-size: 24px;
    font-weight: 700;
    margin: 0.5rem;
}

/* HAMBURGER MENU START */
.toggle_buttons {
    position: absolute;
    display: none;
    width: 30px;
    height: 20px;
    top: 2rem;
    flex-direction: column;
    right: 1rem;
    justify-content: space-between;
}


@media (max-width: 590px) {
    .toggle_buttons {
        display: flex;
    }
    
    .navbar_links {
        display: none;
        width: 100%;
        margin-top: 25px;
        padding-bottom: 15px;
    }
    
    .navbar_links.activate_bar {
        display: flex;
    }
    
    .navbar.navbar_mobile_height {
        height: auto;
    }
}

@media screen and (max-width: 1010px) and (min-width: 590px) {
    .navbar {
        height: 140px;
    }
}
/* HAMBURGER MENU END */

.close_bar_btn {
    position: relative;
}
