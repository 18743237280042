%flexbox_shared {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.profile_root {
    @extend %flexbox_shared;
    flex-direction: column;
    row-gap: 30px;
}

.profile_info_container {
    @extend %flexbox_shared;
    padding: 1rem;
    justify-content: center;
}

.profile_info_wrapper {
    width: 50%;
    @extend %flexbox_shared;
    flex-direction: column;
    flex-wrap: nowrap;
}

.profile_info_company_name {
    margin-bottom: 15px;
    font-style: oblique;
    font-weight: bolder;
}

.profile_info_desc {
    @extend %flexbox_shared;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 15px;
}

.profile_info_desc p {
    font-size: 16px;    
}

.profile_info_image {
    width: 300px;
    height: 200px;
    @extend %flexbox_shared;
}

.profile_info_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profile_list_jobs_container {
    @extend %flexbox_shared;
    flex-direction: column;
    min-width: 100%;
}
.profile_list_jobs_container a {
    width: 100%;    
}

@media screen and (max-width: 935px) {
    .profile_info_wrapper,
    .profile_info_image {
        width: 100%;
    }
    .profile_root .separation_line {
        transform: rotate(0);
        max-width: 100%;
    }

}

@media screen and (max-width: 650px) {
    .profile_root {
        width: 100%;
    }
}

@media screen and (max-width: 715px) {
    .profile_root .left_company_logo {
        display: none;
    }
}

/* JOB ACTIONS START */

.job_actions_delete {
    margin-bottom: 8px;
}

@media screen and (max-width: 450px) {
    .job_actions_delete {
        margin: 0;
    }
}

/* JOB ACTIONS END */