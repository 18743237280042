section.homepage_main {
    width: 90%;
    max-width: 1100px;
    min-width: 326px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 58px;
    border-radius: 15px;
    background-color: var(--header-bg-color);
    position: relative;
}

.show_all_btn_wrapper {
    position: absolute;
    top: -25px;
    width: 147px;
    height: 80px;
}

.show_all_btn_wrapper .show_all_btn {
    background-color: #304FFE;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
}

div.homepage_categories {
    width: 100%;
    padding: 90px 75px 25px 75px;
    display: grid;
    grid-template-columns: repeat(2, minmax(280px, 1fr));/*means 1 fraction => each column will has the same width as the next one*/
    grid-template-rows: repeat(2, 350px);
    column-gap: 200px;
    row-gap: 100px;
}

/* RESPONSIVE */

@media (max-width: 1030px) {
    div.homepage_categories {
        width: auto;
        grid-template-columns: minmax(210px, 330px);
        grid-template-rows: repeat(4, 400px);
    }
}