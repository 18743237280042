.admin_root_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.admin_root_header {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 40px;
}

.admin_root_content {
    flex-grow: 1;
    flex-shrink: 0;
}