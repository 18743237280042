.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}

.dialog {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    border: none;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.dialog_header {
    background-color: #5889db;
    color: white;
    width: 100%;
    padding: 1rem;
}

.dialog_section {
    padding: 1rem;
    font-weight: bold;
}

.dialog_menu {
    padding: 1rem;
    justify-content: flex-end;
    display: flex;
    margin: 0;
}

@media (min-width: 768px) {
    .dialog{
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

.entered {
    animation: openModal 1s ease-out forwards;
}

.exiting {
   animation: closeModal 1.5s ease-out forwards;
}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    
    50% {
        opacity: 0.8;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    
    50% {
        opacity: 0.8;
        transform: translateY(20%);
    }

    100% {
        opacity: 1;
        transform: translateY(-100%);
    }
}