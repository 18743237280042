.applying_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.applying_header {
    font-size: 22px;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}