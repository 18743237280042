/* EACH CATEGORY START*/

article.homepage_category {
    width: 100%;/*must to have to work the grid properly*/
    height: 100%;
    padding: 15px;
    border-radius: 15px;
    background-color: rgba(242, 242, 242, 0.5);;
}

/* CATEGORY HEADER START */

section.homepage_category_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: var(--title-font-size);
    font-weight: 700;
    text-transform: unset;
}

div.homepage_category_title_wrapper {
    display: inline;
    width: 130px;
}

div.homepage_category_title_wrapper > h1 {
    text-transform: uppercase;
}

/* CATEGORY HEADER END */

/* CATEGORY MAIN START*/

section.homepage_category_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    position: relative;
}

div.homepage_category_main_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 5px;
    row-gap: 20px;
    margin-top: 10px;
}

div.homepage_category_show_all_btn_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CATEGORY MAIN END*/

/* EACH CATEGORY END*/

/* RESPONSIVE */
@media screen and (max-width: 500px) {
    .homepage_category_title {
        font-size: 0.8rem;
    }
    
    .homepage_category_header .homepage_category_main_counter {
        max-width: 70px;
        min-width: unset;
    }
}

