.apply_form {
    width: 100%;
    padding: 0 3rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-control {
    width: 100%;
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 460px) {
    .apply_form {
        padding: 0;
    }
}